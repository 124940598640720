export interface UserSkill extends Skill {
  user_id: number;
  skill_id: number;
  created_at: string;
  updated_at: string;
  own_rating: string;
  own_rating_integer_value: number;
  target_rating: string;
  target_rating_integer_value: number;
  manager_rating: string;
  manager_rating_integer_value: number;
}

export interface RatingFramework {
  label: string;
  description: string;
}

export interface Skill {
  id: number;
  name: string;
}

export enum RatingKeys {
  ManagerRatingValue = 'manager_rating_integer_value',
  OwnRatingValue = 'own_rating_integer_value',
  TargetRatingValue = 'target_rating_integer_value',
  ManagerRating = 'manager_rating',
  OwnRating = 'own_rating',
  TargetRating = 'target_rating',
}

export enum MODAL_STATES {
  PICKER = 'picker',
  TARGET_RATING = 'target_rating',
  REQUEST_RATING = 'request_rating',
  OWN_RATING = 'own_rating',
}

export type ModalType = `${MODAL_STATES}`;
